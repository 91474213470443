import React from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import ComingSoonPage from './pages/comingSoonPage';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="*" element={<ComingSoonPage />} /> {/* Catch-all route for 404 pages */}
      </Routes>
    </Router>
  );
}

export default App;
