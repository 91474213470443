import React from "react";
import "./comingSoonPage.css";

const comingSoonPage = () => {
    return (
        <div className="bgimg">
            <div className="middle">
                <h1>COMING SOON</h1>
                <hr/>
                    <a href="https://instagram.com/turkeyultimate">@oyununruhudernegi</a>
            </div>
        </div>
    )
};

export default comingSoonPage;